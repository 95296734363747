import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {graphqlOperation, ProductType} from '../constants';
import {DynamicPaymentMethodsShape} from '@wix/wixstores-client-storefront-sdk/dist/es/src/settingsEnums/productPage';
import {APP_DEFINITION_ID, PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {IProductDTO, IProductPageStyleParams, UserInput} from '../types/app-types';
import {WidgetProps} from '@wix/cashier-express-checkout-widget/dist/src/types/WidgetProps';
import {BreakdownTypes, PaymentBreakdown} from '@wix/cashier-express-checkout-widget/dist/src/types/PaymentBreakdown';
import {IOptionSelectionVariant} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {PaymentAuthorizedArgs} from '@wix/cashier-express-checkout-widget/dist/src/types/ExternalContract';
import {cashierExpressAddressToEcomAddress} from '@wix/wixstores-client-storefront-sdk/dist/src/cart/cashierExpressAddressToEcomAddress/cashierExpressAddressToEcomAddress';
import {CartApi} from '@wix/wixstores-client-storefront-sdk/dist/src/cart/cartApi/CartApi';
import {VolatileCartService} from './VolatileCartService';
import {formatCustomTextFields} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {DirectPurchaseService} from './DirectPurchaseService';
import {ShippingMethod} from '@wix/cashier-express-checkout-widget/src/types/Shipping';
import {translate} from '@wix/wixstores-client-core/dist/src/utils/Translate';
import {
  ShippingContactRestricted,
  ShippingContactSelectedUpdate,
  ShippingError,
} from '@wix/cashier-express-checkout-widget/dist/src/types/Shipping';
import {gqlQuery, gqlStoreFrontQuery} from './getProduct';
import {query as getCountryCodes} from '../graphql/getCountryCodes.graphql';
import {query as getCheckoutSettings} from '../graphql/getCheckoutSettings.graphql';
import {GetCheckoutSettingsQuery, GetCountryCodesQuery} from '../graphql/queries-schema';

export class CashierExpressService {
  public volatileCartService: VolatileCartService;
  private cashierExpressPaymentOrderId: string;
  private cashierExpressWidgetShippingRuleId: string;
  private countryCodes: GetCountryCodesQuery['localeData']['countries'];
  private checkoutSettings: GetCheckoutSettingsQuery['checkoutSettings'];
  private cartId: string;
  private canSelectShippingMethod: boolean;

  constructor(private readonly siteStore: SiteStore, private readonly cartApi: CartApi) {
    this.volatileCartService = new VolatileCartService(this.siteStore.httpClient, this.siteStore);
  }

  public getInitialProps(
    product: IProductDTO,
    styleParams: IProductPageStyleParams
  ): Pick<WidgetProps, 'meta' | 'requestShipping' | 'currency' | 'locale' | 'buttonStyle' | 'domain' | 'demoMode'> {
    return {
      requestShipping: product.productType !== ProductType.DIGITAL,
      buttonStyle: {
        shape:
          styleParams.numbers.productPage_dynamicPaymentMethodsButtonShape === DynamicPaymentMethodsShape.pill
            ? 'pill'
            : 'rect',
        height: 42,
      },
      domain: this.siteStore.location.baseUrl,
      meta: {
        appDefId: APP_DEFINITION_ID,
        appInstanceId: this.siteStore.storeId,
        siteId: this.siteStore.msid,
        visitorId: this.siteStore.uuid as string,
        productId: product.id,
      },
      currency: this.siteStore.currency,
      locale: this.siteStore.locale,
      demoMode: this.siteStore.isEditorMode(),
    };
  }

  public getPaymentBreakdown(
    product: IProductDTO,
    selectedVariant: IOptionSelectionVariant,
    userInputs: UserInput
  ): Pick<WidgetProps, 'paymentAmount' | 'paymentBreakdown'> {
    if (this.volatileCartService.initialPartialCart) {
      const totals = this.volatileCartService.initialPartialCart.totals;

      return {
        paymentAmount: totals.total.toString(),
        paymentBreakdown: {
          [BreakdownTypes.ItemsTotal]: totals.subtotal.toString(),
          [BreakdownTypes.Shipping]: totals.shipping.toString(),
          [BreakdownTypes.Tax]: totals.tax.toString(),
          [BreakdownTypes.Discount]: totals.discount.toString(),
        },
      };
    } else {
      const quantity = userInputs.quantity[0];

      const actualProduct = selectedVariant || product;
      const price = ((actualProduct.comparePrice || actualProduct.price) * quantity).toString();

      return {
        paymentAmount: price,
        paymentBreakdown: {
          [BreakdownTypes.ItemsTotal]: price,
          [BreakdownTypes.Shipping]: '0',
          [BreakdownTypes.Tax]: '0',
          [BreakdownTypes.Discount]: '0',
        },
      };
    }
  }

  public async handleCashierPaymentSubmit(
    paymentInfo: PaymentAuthorizedArgs,
    accessibilityEnabled: boolean,
    product: IProductDTO
  ): Promise<'success' | 'fail' | 'shouldNavigateToCheckout'> {
    const shouldRequestShipping = product.productType !== ProductType.DIGITAL;

    if (shouldRequestShipping) {
      await this.cartApi.setCartAddresses(
        this.volatileCartService.cartId,
        cashierExpressAddressToEcomAddress(paymentInfo.shippingContact, paymentInfo.billingContact, this.countryCodes)
      );
    } else {
      await this.cartApi.setCartBillingAddresses(
        this.volatileCartService.cartId,
        cashierExpressAddressToEcomAddress({}, paymentInfo.billingContact, this.countryCodes)
      );
    }
    const termsAndConditionsDisabled = !this.checkoutSettings.termsAndConditions.enabled;
    const cart = await this.volatileCartService.getCart();
    const notEnoughInfoAboutSubdivision = cart.cartService.cart.destinationCompleteness.includes('SUBDIVISION');
    const noMissingShippingData =
      !notEnoughInfoAboutSubdivision &&
      ((cart.cartService.cart.shippingRuleInfo?.canShipToDestination && this.canSelectShippingMethod) ||
        !shouldRequestShipping);

    const canPayWithoutNavigatingToCheckout = termsAndConditionsDisabled && noMissingShippingData;
    if (canPayWithoutNavigatingToCheckout) {
      const placeOrderResponse = await this.cartApi.placeOrder({
        cartId: this.volatileCartService.cartId,
        paymentId: paymentInfo.detailsId,
        shouldRedirect: true,
        isPickupFlow: false,
        inUserDomain: true,
        forceLocale: this.siteStore.locale,
        deviceType: this.siteStore.isMobile() ? 'mobile' : 'desktop',
      });

      const wasPlaceOrderSuccessful = placeOrderResponse.cartStatus.success;
      if (!wasPlaceOrderSuccessful) {
        return 'fail';
      }

      this.cashierExpressPaymentOrderId = placeOrderResponse.orderId;

      return 'success';
    } else {
      return 'shouldNavigateToCheckout';
    }
  }

  public async onCashierExpressPaymentSuccess() {
    await this.siteStore.navigate(
      {
        sectionId: PageMap.THANKYOU,
        queryParams: {objectType: 'order'},
        state: this.cashierExpressPaymentOrderId,
      },
      true
    );
  }

  private fetchCountryCodes() {
    return gqlStoreFrontQuery(this.siteStore, getCountryCodes, {}, graphqlOperation.GetCountryCodes);
  }

  private fetchCheckoutSettings() {
    return gqlQuery(this.siteStore, getCheckoutSettings, {}, graphqlOperation.GetCheckoutSettings);
  }

  public async fetchInitialData(product: IProductDTO, userInputs: UserInput) {
    const standaloneCartIdPromise = this.volatileCartService.getStandaloneCartId(
      product.id,
      userInputs.selection.map((selected) => selected.id),
      userInputs.quantity[0],
      formatCustomTextFields(product, userInputs).map((customTextField) => {
        return {title: customTextField.customText.title, value: customTextField.answer};
      })
    );

    const [checkoutSettings, countryCodes, cartId] = await Promise.all([
      this.fetchCheckoutSettings(),
      this.fetchCountryCodes(),
      standaloneCartIdPromise,
    ]);

    this.countryCodes = countryCodes.data.localeData.countries;
    this.checkoutSettings = checkoutSettings.data.checkoutSettings;
    this.cartId = cartId;
  }

  public async handleCashierOnClick(
    product: IProductDTO,
    directPurchaseService: DirectPurchaseService,
    userInputs: UserInput
  ): Promise<boolean> {
    this.canSelectShippingMethod = false;
    const canCheckout = await directPurchaseService.handleCashierOnClick(product);
    if (canCheckout) {
      await this.fetchInitialData(product, userInputs);
      await this.siteStore.biLogger.clickOnCheckoutWithEWalletSf({
        origin: 'product page',
        type: 'payPal',
        productsList: product.id,
        cartId: this.cartId,
      });
    }

    return canCheckout;
  }

  public async onShippingMethodSelected(
    shippingMethod: ShippingMethod
  ): Promise<Pick<WidgetProps, 'paymentAmount' | 'paymentBreakdown'>> {
    await this.cartApi.setShippingOption(this.volatileCartService.cartId, {
      shippingRuleId: this.cashierExpressWidgetShippingRuleId,
      optionId: shippingMethod.identifier,
    });
    this.canSelectShippingMethod = true;

    const cart = await this.volatileCartService.getCart();
    const paymentAmount = cart.cartService.cart.totals.total.toString();
    const totals = cart.cartService.cart.totals;

    const paymentBreakdown: PaymentBreakdown = {
      shipping: totals.shipping.toString(),
      tax: totals.tax.toString(),
      discount: totals.discount.toString(),
      itemsTotal: totals.itemsTotal.toString(),
    };

    return {paymentAmount, paymentBreakdown};
  }

  public async fetchPaymentBreakdownForCashierAddress(
    shippingAddress: ShippingContactRestricted,
    translations
  ): Promise<ShippingContactSelectedUpdate> {
    const {country, subdivision, zipCode} = cashierExpressAddressToEcomAddress(shippingAddress, {}, this.countryCodes);

    await this.cartApi.setShippingAddressesForFastFlow(this.volatileCartService.cartId, {
      country,
      subdivision,
      zipCode,
    });

    const cart = await this.volatileCartService.getCart();

    this.cashierExpressWidgetShippingRuleId = cart.cartService.cart.shippingRuleInfo.shippingRule?.id;
    const shippingMethods: ShippingMethod[] = cart.cartService.cart.shippingRuleInfo.shippingRule?.options.map(
      (shippingMethod) => {
        return {
          label: shippingMethod.title,
          amount: shippingMethod.rate.toString(),
          identifier: shippingMethod.id,
          detail:
            (shippingMethod.pickupInfo
              ? translate(translations['productPage.shippingOption.pickup.addressFormatSubdivision'], {
                  addressLine: shippingMethod.pickupInfo.address.addressLine,
                  city: shippingMethod.pickupInfo.address.city,
                  subdivision: shippingMethod.pickupInfo.address.subdivisionName,
                  country: shippingMethod.pickupInfo.address.countryName,
                  zipCode: shippingMethod.pickupInfo.address.zipCode,
                })
              : shippingMethod.deliveryTime) || '',
        };
      }
    );

    const notEnoughInfoAboutSubdivision = cart.cartService.cart.destinationCompleteness.includes('SUBDIVISION');

    const totals = cart.cartService.cart.totals;

    const initialPaymentBreakdown: PaymentBreakdown = {
      shipping: totals.shipping.toString(),
      tax: totals.tax.toString(),
      discount: totals.discount.toString(),
      itemsTotal: totals.subTotal.toString(),
    };

    /* istanbul ignore else: todo(eran): test else */
    if (cart.cartService.cart.shippingRuleInfo.canShipToDestination || notEnoughInfoAboutSubdivision) {
      return {
        paymentAmount: cart.cartService.cart.totals.total.toString(),
        paymentBreakdown: initialPaymentBreakdown,
        shippingMethods,
      };
    } else {
      return {error: ShippingError.SHIPPING_ADDRESS_UNSERVICEABLE};
    }
  }
}
